namespace workpapers {
  "use strict";

  interface IWpContextHeaderComponentController {
    taxYear: string;
    accountId: string;
    locatorName: string;
    atlasId: string;
    allWorkpapersSigned: boolean;

    finalizeWorkpapers(): void;
    sendToGoSystem(): void;
    returnToLocator(): void
  }

  interface IWpContextHeaderScope extends ng.IScope {
    goSystemBusyLoader: boolean;
    finalizeWorkpapersBusyLoader: boolean;
  }

  class WpContextHeaderComponentController implements IWpContextHeaderComponentController {
    public static $inject = ["$scope", "$rootScope", "$ledoconfig", "$http", "config"];

    constructor(private $log: ng.ILogService, private $scope: IWpContextHeaderScope, private $rootScope: any, private $ledoconfig: any,
      private $http: any, private config: any, public taxYear: string, public accountId: string, public locatorName: string,
      public atlasId: string, public firmId: string, public userId: string, public locator: string,
      public allWorkpapersSigned: boolean) {

      // listen for broadcast of workpapers-all-signed     
      this.$scope.$on("workpapers-all-signed", (event: ng.IAngularEvent, allSigned: boolean) => {
        this.allWorkpapersSigned = allSigned;
      });
    }

    public sendToGoSystem(): void {
      const errorMsg = "Failed to send to GoSystem Tax RS";
      const successMsg = "Sending to GoSystem Tax RS";
      let that = this;

      this.$scope.goSystemBusyLoader = true;
      let defer = this.$http.post(this.config.api + "/api/locator/status?status=IMPORTING");

      defer.then(function (response) {
        if (response.data.status === "success") { // if there is a success handler           
          that.$rootScope.addAlert("success", successMsg, 5000);
        } else {
          that.$rootScope.addAlert("danger", errorMsg);
        }
      }).catch((response) => {
        that.$rootScope.addAlert("danger", errorMsg);
      }).finally((response) => {
        that.$scope.goSystemBusyLoader = false;
      });
    }

    public finalizeWorkpapers(): void {
      const errorMsg = "Failed to finalize workpapers";
      const successMsg = "Workpapers submitted to queue";
      let that = this;

      this.$scope.finalizeWorkpapersBusyLoader = true;
      let defer = this.$http.post(this.config.api + "/api/workpapers/finalize", null,
        {
          headers: {
            accountId: this.accountId,
          },
        });

      defer.then(function (response) {
        if (response.data.status === "success") { // if there is a success handler           
          that.$rootScope.addAlert("success", successMsg, 5000);
        } else {
          that.$rootScope.addAlert("danger", errorMsg);
        }
      }).catch((response) => {
        that.$rootScope.addAlert("danger", errorMsg);
      }).finally((response) => {
        that.$scope.finalizeWorkpapersBusyLoader = false;
      });
    }

    public returnToLocator(): void {
      console.log("returnToLocator clicked");
    }

    /* tslint:disable:no-unused-variable */
    private $onInit() {
      this.$log.debug("Initializing WpContextHeader");

      this.taxYear = this.$ledoconfig.getCookieStorage("taxYear");
      this.accountId = this.$ledoconfig.getCookieStorage("accountId");
      this.locatorName = this.$ledoconfig.getCookieStorage("locatorTaxPayerName");
      this.atlasId = this.$ledoconfig.getCookieStorage("taxpayerUserId");

      this.firmId = this.$ledoconfig.getCookieStorage("firmId");
      this.userId = this.$ledoconfig.getCookieStorage("userId");
      this.locator = this.$ledoconfig.getCookieStorage("locator");
    }
  }

  class WpContextHeader {
    public templateUrl: string;
    public controllerAs: string;
    public controller: any;

    constructor() {
      this.templateUrl = "/app/components/workpapers/wpcontextheader/wpcontextheader.html";
      this.controllerAs = "model";
      this.controller = ["$log", "$scope", "$rootScope", "$ledoconfig", "$http", "config", WpContextHeaderComponentController];
    }
  }

  angular.module("workpapers").component("wpContextHeader", new WpContextHeader());
}