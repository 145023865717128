namespace workpapers {
    "use strict";

    export class WorkbookService {
        /* tslint:disable:no-empty */
        constructor(public $log: ng.ILogService, public $http: ng.IHttpService, public config: any) {}

        public getAsync(worksheetId: number, locatorId: string, taxYear: number): ng.IHttpPromise<{}> {
            const defer = this.$http.post(this.config.api + "/api/workpapers/grid", {
                    workpaperId: worksheetId,
                    locator:  locatorId,
                    taxYear: taxYear,
                });
            return defer;
        };
    }

    angular
        .module("workpapers")
        .service("WorkbookService", WorkbookService);
}
