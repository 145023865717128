namespace workpapers {
    "use strict";

    interface ICellSelection {
        previouslySaved?: boolean;
        tag: string;
        invalidSelection?: boolean;
        showSlider: boolean;
        translationMap: Object;
      }

    export class WpCellService {    
        public spreadsheetConstants: any;
        
        constructor(public $log: ng.ILogService, public $http: ng.IHttpService, public $rootScope: ng.IRootScopeService, 
            public config: any, private wpConstants: any) { 
                this.spreadsheetConstants = wpConstants.spreadsheet.getCurrentYear();
            }

        public saveAsync(eventType: string,  taxYear: string, locatorName: string, locatorId: string, row: string, 
          cellUpdates: CellUpdate[], sheetName: string, workpaperId: string, accountId: string, 
          userName: string): ng.IPromise<any> {
            const defer = this.$http.post("/api/workpapers/cell/save", {
                taxYear: taxYear,
                locatorName: locatorName,
                locatorId: locatorId,
                sheetName: sheetName,
                workpaperId: workpaperId,
                accountId: accountId,
                userName: userName,
                cellUpdates: cellUpdates,
                row: row,
            });
            return defer;
        }

        public broadcastSelection(cellInfo: any, showSlider: boolean): void {
            let request = <ICellSelection>{};

            request.showSlider = showSlider;

            if (cellInfo === undefined || cellInfo == null) {
                request.invalidSelection = true;
            } else {
                request.previouslySaved = cellInfo.questionKey != null 
                    && cellInfo.questionKey.indexOf(this.spreadsheetConstants.newAnswerIndicator) === -1;

                if (request.previouslySaved) {
                    request.tag = cellInfo.tag;

                    if (typeof cellInfo.translationMap !== "undefined") {
                        request.translationMap = cellInfo.translationMap;
                    }
                }
            }     
      
            this.$rootScope.$broadcast("cellSelected", request);
          }
    }

    class CellUpdate {
        public questionKey: string;
        public newValue: string;
        public dynamic: boolean;
        public locatorScenario: string;
    }

    angular
        .module("workpapers")
        .service("WpCellService", WpCellService);
}
