namespace workpapers {
    "use strict";

    interface ISignOffService {
        isUiLocked?(): boolean;

        setLocks(spread: any, setLock: boolean, workpaperId: number): void;
    }

    class WpSignOffService implements ISignOffService {
        //if sheet is signed off, editing will be disable. do not edit directly. use setLocks()
        private lockAll?: boolean;

        constructor(public $log: ng.ILogService) {
        }

        public isUiLocked?(): boolean {
            return this.lockAll;
        }

        public setLocks(spread: any, setLock: boolean, workpaperId: number): void {
            this.lockAll = setLock;

            this.$log.debug("Locks set to: " + this.lockAll);

            const lockedTag = "ssjson lock"; //to maintain ssjson's locked cells

            spread.isPaintSuspended(true);
            spread.suspendCalcService(false);

            for (let sheetIndex = 0; sheetIndex < spread.getSheetCount(); sheetIndex++) {
                let sheet: any = spread.getSheet(sheetIndex);

                //if the sheet isn't lockable, skip it to improve performance
                if (!this.isSheetLockable(sheet, workpaperId)) {
                    continue;
                }

                this.$log.debug("Sheet is being locked or unlocked: " + sheet._name);

                sheet.setIsProtected(false);

                //when the sheet is locked, only the 
                sheet.protectionOption().allowSelectLockedCells = setLock;

                for (let rowIndex = 0; rowIndex < sheet.getRowCount(); rowIndex++) {
                    if (!sheet.getRowVisible(rowIndex)) {
                        continue;
                    }

                    for (let colIndex = 0; colIndex < sheet.getColumnCount(); colIndex++) {
                        let cell = sheet.getCell(rowIndex, colIndex);
                        //if locking
                        if (setLock && !cell.locked()) {
                            cell.tag(lockedTag);
                            cell.locked(true);
                            this.$log.debug("tagged row/col: " + rowIndex + "/" + colIndex);
                            continue;
                        }

                        //if unlocking
                        if (!setLock && cell.locked() && sheet.getTag(rowIndex, colIndex) === lockedTag) {
                            this.$log.debug("unlocked: " + rowIndex + "/" + colIndex);
                            cell.locked(false);
                            continue;
                        }
                    }
                }

                sheet.setIsProtected(true);
            }

            spread.resumeCalcService(true);
            spread.isPaintSuspended(false);
        }

        private isSheetLockable(sheet: any, workpaperId: number) {
            const summary = " Summary";

            if (!sheet._visible || sheet._name === "Sheet1") {
                return false;
            }

            switch (workpaperId) {
                case 1: //comp
                    return (sheet._name.indexOf(summary) === -1); //if it doesn't contain " summary", return true
                case 2: //iftc
                case 3: //ss
                case 4: //optimized
                    return true;
                case 5: //decision tree not lockable b/c sign-off doesn't apply
                    return false;
                default:
                    this.$log.error("invalid workpaperId specified: " + workpaperId);
            }

            return false;
        }
    }

    angular
        .module("workpapers")
        .service("WpSignOffService", WpSignOffService);
}