namespace workpapers {
    "use strict";

    interface IFeatureService {
        getRowConfig(workpaperId: number): RowConfig;
        supportsHidingRows(workpaperId: number): boolean;
    }

    class RowConfig {
        public hideAbove: number;
        public hideBelow: number;
        public hideTreaty: number;
    }

    class WpFeatureService implements IFeatureService {
        public static $inject = ["$log"];

        constructor(public $log: ng.ILogService) { }

        public getRowConfig(workpaperId: number): RowConfig {
            const rowConfig: RowConfig = new RowConfig();

            switch (workpaperId) {
                case 2: //fall through                    
                case 3:
                    rowConfig.hideAbove = 1;
                    rowConfig.hideBelow = 3;
                    break;
                case 4: //don't hide above or below, just hide the row itself
                    rowConfig.hideAbove = 0;
                    rowConfig.hideBelow = 0;
                    break;
                default:
                    this.$log.error("Row config not defined for: " + workpaperId);
            }

            // hideTreaty only applies to IFTC
            rowConfig.hideTreaty = (workpaperId === 2) ? 5 : 0;

            return rowConfig;
        }

        public supportsHidingRows(workpaperId: number): boolean {
            return workpaperId !== 1;
        }
    }

    angular
        .module("workpapers")
        .service("WpFeatureService", WpFeatureService);
}
