namespace workpapers {
    "use strict";

    interface IGroupService {
        populateStatic(sheetName: string, sheetInternalObj: any, sheetDataMap: any, groupName: string): Group;
    }

    export class Group {
        public RowUpdates: any[];
        public NewQuestionKeys: any;
        public HasExistingAnswers?: boolean;
    }

    class WpGroupService implements IGroupService {
        public static $inject = ["$log", "_", "wpConstants"];

        private spreadsheetConstants: any;

        constructor(public $log: ng.ILogService, public _: any, public wpConstants: any) {
            this.spreadsheetConstants = wpConstants.spreadsheet.getCurrentYear();
        }

        public populateStatic(sheetName: string, sheetInternalObj: any, sheetDataMap: any, groupName: string) {
            let cellNames = sheetInternalObj.groups[groupName].cellNames;
            let continueChecking = true;

            let newQuestionKeys = {};
            let rowUpdates = [];

            let firstCellName;
            let keyCount = 0;
            let newQuestionKeyCount = 0;
            let emptyProperties = 0;

            while (continueChecking) {
                let rowUpdate = {};
                continueChecking = false;

                for (let baseCellName in cellNames) {
                    if (!cellNames.hasOwnProperty(baseCellName)) {
                        continue;
                    }

                    //if the for loop starts a second time, break out
                    if (firstCellName !== undefined && firstCellName === baseCellName) {
                        continueChecking = false;
                        break;
                    } else if (firstCellName === undefined) {
                        //first time the loop runs
                        firstCellName = baseCellName;
                    }

                    keyCount++;

                    let actualCellName = baseCellName;

                    if (sheetDataMap[actualCellName]) {
                        rowUpdate[baseCellName] = sheetDataMap[actualCellName];

                        let currentQuestionKey = sheetDataMap[actualCellName].questionKey;
                        let val = sheetDataMap[baseCellName].value;

                        if (!this._.isNil(currentQuestionKey)) {
                            if (currentQuestionKey.indexOf(this.spreadsheetConstants.newAnswerIndicator) !== -1) {
                                newQuestionKeys[baseCellName] = currentQuestionKey;
                                newQuestionKeyCount++;
                            } else if ((typeof val === "string" || val instanceof String) && val.trim() === "") {
                                emptyProperties++;
                            }
                        }

                        continueChecking = true;
                    }

                    if (continueChecking) {
                        rowUpdates.push(rowUpdate);
                    }
                }
            }

            const staticGroup: Group = {
                RowUpdates: rowUpdates,
                NewQuestionKeys: newQuestionKeys,
                //if you have more keys than new question keys + emptyProperties, than the section has data
                HasExistingAnswers: keyCount !== (newQuestionKeyCount + emptyProperties),
            }

            return staticGroup;
        }
    }

    angular
        .module("workpapers")
        .service("WpGroupService", WpGroupService);
}
