namespace workpapers {
    "use strict";

    export class OptimizeService {
        constructor(public $log: ng.ILogService, public $http: ng.IHttpService, public config: any) { }

        public postAsync(): ng.IHttpPromise<{}> {
            const defer = this.$http.post(this.config.api + "/api/workpapers/optimize", {});
            return defer;
        };
    }

    angular
        .module("workpapers")
        .service("OptimizeService", OptimizeService);
}