namespace workpapers {
    "use strict";

    interface IWpBodyMainComponentController {
        leftPanelWidth: number;

        trackEvent(eventName: String): void;
    }

    export type Workpaper = {
        taxYear: string;
        locatorId: string;
        id: number;
    }

    interface IBodyMainScope extends IAuditNoteVm, ng.IScope { }

    interface IAuditNoteVm {
        showSlider: boolean;
    }

    class WpBodyMainComponentController implements IWpBodyMainComponentController {
        public static $inject = ["$scope", "$rootScope", "$ledoconfig"];

        constructor(public $log: ng.ILogService, private $scope: IBodyMainScope, private $rootScope: ng.IRootScopeService, 
            private $ledoconfig: any, public leftPanelWidth: number, public workpaper: Workpaper) {
            
            // listen for broadcast of left-nav-loaded     
            this.$scope.$on("left-nav-loaded", (event: ng.IAngularEvent) => {
                this.leftPanelWidth = document.getElementById("splitter-group-left").clientWidth;
            });

            //if this breaks, check to see if MTI fixed the spelling of clicked
            let rsShowNotesClicked = this.$rootScope.$on("showNotesClicked", (event: ng.IAngularEvent) => {
                this.$scope.showSlider = !this.$scope.showSlider;
            });

            this.$scope.$on("$destroy", rsShowNotesClicked);
        }

        public trackEvent(eventName: String): void {
            this.$scope.$broadcast("resize-spreadjs-grid", this.leftPanelWidth);
        }

        /* tslint:disable:no-unused-variable */
        private $onInit() {
            this.$log.debug("Initializing WpBodyMain");

            this.$rootScope.$emit("DisableNoteSliderFlag", false);

            $.getScript("app/components/workpapers/spreadjs/scripts/interop/angular.gcspread.sheets.9.40.20153.0.min.js")
                .fail(function (jqxhr, settings, exception) {
                    let responseText = jQuery.parseJSON(jqxhr.responseText);
                    console.log(settings + " - " + responseText.error + ": " + responseText.path);
                });

            this.workpaper = {
                taxYear: this.$ledoconfig.getCookieStorage("taxYear"),
                locatorId: this.$ledoconfig.getCookieStorage("locator"),
                id: 0,
            }
        }
    }

    class WpBodyMain implements ng.IComponentOptions {
        public templateUrl: string;
        public controllerAs: string;
        public controller: any;

        constructor() {
            this.templateUrl = "/app/components/workpapers/wpbodymain/wpbodymain.html";
            this.controllerAs = "model";
            this.controller = ["$log", "$scope", "$rootScope", "$ledoconfig", WpBodyMainComponentController];
        }
    }

    angular.module("workpapers").component("wpBodyMain", new WpBodyMain());
}