namespace workpapers {
    "use strict";

    export type locatorEntry = {
        locatorId: string;
        workpaperId: string;
    }

    export class WpLoginStateService {

        public static $inject = ["$log", "$http", "$ledoconfig"];

        public selectedCell: locatorEntry;


        /* tslint:disable:no-empty */
        constructor(public $log: ng.ILogService, public $http: ng.IHttpService, public config: any) {
        }

        public updateLocator(locator: locatorEntry): ng.IPromise<any> {
            const defer = this.$http.post("/api/workpapers/loginState/save", {
                locatorId: locator.locatorId,
                workpaperId: locator.workpaperId,
            });
            return defer;
        };
        
        public getLoginStateAsync(locatorId: string): ng.IPromise<any> {
            const defer = this.$http.post("/api/workpapers/loginState/get", {
                locatorId: locatorId,
             });
            return defer;
        };
        
        
    }

    angular
        .module("workpapers")
        .service("WpLoginStateService", WpLoginStateService);
}
