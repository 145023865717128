namespace workpapers {
    "use strict";

    enum WorkpaperTypes {
        Compensation = 1,
        ForeignTaxes,
        StateSourcing,
        Optimized,
        DecisionTree,
    }

    interface INavTreeItem {
        id: number,
        name: string,
        iconName: string,
        mySecondaryIcon: string,
        items: string[],
        expandOnly: boolean,
        collapsed: boolean,
        checked: boolean,
        selected: boolean,
        hideCheckbox: boolean,
        toggleState: boolean,
        hideSignOff: boolean,
        parent: INavTreeItem;

        onToggleChange(value): void;
    }

    interface ILoginSate {
        lastWorkpaperId: number,
    }

    interface IWpListComponentController {
        showBusyLoader: boolean;
        treeList: Array<INavTreeItem>;
        currentNavItem: INavTreeItem;

        loadTreeList(): void;

        selectedItemChanged(item: INavTreeItem): boolean;

        updateToggleStates(workpaperType: WorkpaperTypes, value: boolean, saveToDb?: boolean): void;

        onToggleChange(workpaperType: WorkpaperTypes, value: boolean, saveToDb?: boolean): void;
    }

    interface IWpListScope extends ng.IScope {
        navCompensation: INavTreeItem;
        navForeignTaxes: INavTreeItem;
        navStateSourcing: INavTreeItem;
        navOptimized: INavTreeItem;
        navDecisionTree: INavTreeItem;
        showBusyLoader: boolean;
        showOptimized: boolean;

        confirmUnsign(WorkpaperTypes): void;

        unsignCancel(): void;

        unsignContinue(): void;
    }

    class WpListComponentController implements IWpListComponentController {
        public static $inject = ["$log", "$rootScope", "$scope", "$ledoconfig", "$uibModal", "WpWorkpaperService",
            "WpLoginStateService", "config"];

        constructor(private $log: ng.ILogService, private $rootScope: any,
                    private $scope: IWpListScope, public $ledoconfig: any, public $uibModal: any, public WpWorkpaperService: any,
                    public WpLoginStateService: any, private config: any, public showBusyLoader: boolean,
                    public treeList: Array<INavTreeItem>, public currentNavItem: INavTreeItem, private modalInstance) {
        }

        public loadTreeList(): void {
            this.treeList = [];
            this.initializeWorkpapers(WorkpaperTypes.Compensation);
            this.initializeWorkpapers(WorkpaperTypes.ForeignTaxes);
            this.initializeWorkpapers(WorkpaperTypes.StateSourcing);
            this.treeList.push(this.$scope.navCompensation);
            this.treeList.push(this.$scope.navForeignTaxes);
            this.treeList.push(this.$scope.navStateSourcing);

            // ADS 6/12/18: hiding Optimized and Decision Tree Summary workpapers 
            // for KPMG users but still want visible for our testing
            if (this.config.ShowAllWorkpapers) {
                if (this.$scope.showOptimized) {
                    this.initializeWorkpapers(WorkpaperTypes.Optimized);
                    this.treeList.push(this.$scope.navOptimized);
                }

                this.initializeWorkpapers(WorkpaperTypes.DecisionTree);
                this.treeList.push(this.$scope.navDecisionTree);
            }

            // initialize as Compensation b/c that's what's selected by default
            this.currentNavItem = {} as INavTreeItem;

            this.$scope.confirmUnsign = (WorkpaperType) => this.confirmUnsign(WorkpaperType);
        }

        public selectedItemChanged = (item: INavTreeItem): boolean => {
            // if id doesn't exist then this is the initial load; set the nav item and do nothing else
            if (!this.currentNavItem.id) {
                this.currentNavItem = item;
                return false;
            }

            // do nothing if selected item is the same as the current item
            if (this.currentNavItem.id === item.id) {
                return false;
            }

            // if we get here then nav selection has changed and a returntype is selected so broadcast out
            if (this.$scope.navForeignTaxes.selected === true) {
                this.$scope.navForeignTaxes.selected = false;
            }
            if (this.$scope.navCompensation.selected === true) {
                this.$scope.navCompensation.selected = false;
            }
            if (this.$scope.navStateSourcing.selected === true) {
                this.$scope.navStateSourcing.selected = false;
            }
            if (this.$scope.navOptimized.selected === true) {
                this.$scope.navOptimized.selected = false;
            }
            if (this.$scope.navDecisionTree.selected === true) {
                this.$scope.navDecisionTree.selected = false;
            }

            this.currentNavItem = item;
            this.$rootScope.$broadcast("workpaper-changed", this.currentNavItem.id, item.toggleState);
            return true;
        }

        public onToggleChange(workpaperType: WorkpaperTypes, value: boolean, saveToDb?: boolean): void {
            if (!value) {
                this.confirmUnsign(workpaperType);
                return;
            }

            this.updateToggleStates(workpaperType, value, saveToDb);
        }

        public updateToggleStates(workpaperType: WorkpaperTypes, value: boolean, saveToDb?: boolean): void {
            //on initial load, we get the values from the database. after toggling the ones that are true in database, we don't want to save
            if (!saveToDb) {
                this.updateToggleStatesGui(workpaperType, value);
                return;
            }

            this.saveSignOff(workpaperType, value);
        }

        public saveSignOff = (workpaperType: WorkpaperTypes, value: boolean): void => {
            const error = "Sign off has failed. Please refresh your screen and try again.";
            let that = this;

            const locatorId: string = this.$ledoconfig.getCookieStorage("locator");
            let defer = this.WpWorkpaperService.signOffAsync(locatorId, workpaperType, value);

            defer.then(function (response) {
                if (response.data.status === "success") { // if there is a success handler           
                    that.updateToggleStatesGui(workpaperType, value);
                } else {
                    that.$rootScope.addAlert("danger", error);
                    that.updateToggleStatesGui(workpaperType, !value);
                }
            }).catch((response) => {
                that.updateToggleStatesGui(workpaperType, !value);
                that.$rootScope.addAlert("danger", error);
            });
        }

        public initializeWorkpapers(workpaperType: WorkpaperTypes): void {
            switch (workpaperType) {
                case WorkpaperTypes.Compensation:
                    this.$scope.navCompensation.id = WorkpaperTypes.Compensation;
                    this.$scope.navCompensation.name = "Compensation";
                    this.$scope.navCompensation.selected = true;
                    this.$scope.navCompensation.onToggleChange = (value) => {
                        this.onToggleChange(WorkpaperTypes.Compensation, value, true);
                    };
                    break;
                case WorkpaperTypes.ForeignTaxes:
                    this.$scope.navForeignTaxes.id = WorkpaperTypes.ForeignTaxes;
                    this.$scope.navForeignTaxes.name = "Income & FTC Summary";
                    this.$scope.navForeignTaxes.onToggleChange = (value) => {
                        this.onToggleChange(WorkpaperTypes.ForeignTaxes, value, true);
                    };
                    break;

                case WorkpaperTypes.StateSourcing:
                    this.$scope.navStateSourcing.id = WorkpaperTypes.StateSourcing;
                    this.$scope.navStateSourcing.name = "State Sourcing";
                    this.$scope.navStateSourcing.onToggleChange = (value) => {
                        this.onToggleChange(WorkpaperTypes.StateSourcing, value, true);
                    };
                    break;

                case WorkpaperTypes.Optimized:
                    this.$scope.navOptimized.id = WorkpaperTypes.Optimized;
                    this.$scope.navOptimized.name = "Overall Determination";
                    this.$scope.navOptimized.onToggleChange = (value) => {
                        this.onToggleChange(WorkpaperTypes.Optimized, value, true);
                    };
                    break;

                case WorkpaperTypes.DecisionTree:
                    // the sign-off toggle doesn't apply for this workpaper so hide it
                    this.$scope.navDecisionTree.id = WorkpaperTypes.DecisionTree;
                    this.$scope.navDecisionTree.name = "Decision Tree Summary";
                    this.$scope.navDecisionTree.hideSignOff = true;
                    break;
            }

            this.$scope.confirmUnsign = (WorkpaperType) => this.confirmUnsign(WorkpaperType);
        }

        public getLoginState(compensationToggleState: boolean, foreignTaxesToggleState: boolean, stateSourcingToggleState: boolean,
                             optimizedToggleState: boolean): any {
            const locatorId: string = this.$ledoconfig.getCookieStorage("locator");

            let itemSelected = {} as INavTreeItem;
            let defer = this.WpLoginStateService.getLoginStateAsync(locatorId);
            let errormsg = "Workpapers failed to load. Please refresh your screen and try again.";
            let loginState = {} as ILoginSate;
            let currentnavtoggleState = false;
            let that = this;

            defer.then(function (response) {
                if (response.data.status === "success") { // if there is a success handler           
                    if (response.data.loginDto !== null) {
                        loginState.lastWorkpaperId = response.data.loginDto.workpaperId;

                        // for the navitems, Comp is selected by default b/c it's first; if last selected
                        // wasn't comp, then turn it off and turn on the correct one
                        // ADS 5/10/18: we now hide Optimized wp if Decision Trees decides it's not relevant; as 
                        //      a result, we will show Comp if last selected was Optimized but no longer relevant
                        // ADS 6/12/18: we added a flag to optionally hide Optimized and Decision Tree Summary from KPMG;
                        //      as a result, we will show Comp if last selected was either of them and now hidden
                        if (loginState.lastWorkpaperId === WorkpaperTypes.Compensation ||
                            (!that.$scope.showOptimized && loginState.lastWorkpaperId === WorkpaperTypes.Optimized) ||
                            (!that.config.ShowAllWorkpapers && (loginState.lastWorkpaperId === WorkpaperTypes.Optimized ||
                                loginState.lastWorkpaperId === WorkpaperTypes.DecisionTree))) {
                            currentnavtoggleState = compensationToggleState;
                            that.$scope.navCompensation.selected = true;
                            itemSelected = that.$scope.navCompensation;
                            that.currentNavItem = itemSelected;
                            if (!currentnavtoggleState) {
                                that.updateToggleStates(WorkpaperTypes.Compensation, currentnavtoggleState);
                            }
                        }
                        else if (loginState.lastWorkpaperId === WorkpaperTypes.ForeignTaxes) {
                            currentnavtoggleState = foreignTaxesToggleState;
                            that.$scope.navCompensation.selected = false;
                            that.$scope.navForeignTaxes.selected = true;
                            itemSelected = that.$scope.navForeignTaxes;
                            that.currentNavItem = itemSelected;
                            if (!currentnavtoggleState) {
                                that.updateToggleStates(WorkpaperTypes.ForeignTaxes, currentnavtoggleState);
                            }

                        }
                        else if (loginState.lastWorkpaperId === WorkpaperTypes.StateSourcing) {
                            currentnavtoggleState = stateSourcingToggleState;
                            that.$scope.navCompensation.selected = false;
                            that.$scope.navStateSourcing.selected = true;
                            itemSelected = that.$scope.navStateSourcing;
                            that.currentNavItem = itemSelected;
                            if (!currentnavtoggleState) {
                                that.updateToggleStates(WorkpaperTypes.StateSourcing, currentnavtoggleState);
                            }
                        }
                        else if (loginState.lastWorkpaperId === WorkpaperTypes.Optimized) {
                            currentnavtoggleState = optimizedToggleState;
                            that.$scope.navCompensation.selected = false;
                            that.$scope.navOptimized.selected = true;
                            itemSelected = that.$scope.navOptimized;
                            that.currentNavItem = itemSelected;
                            if (!currentnavtoggleState) {
                                that.updateToggleStates(WorkpaperTypes.Optimized, currentnavtoggleState);
                            }
                        }
                        else if (loginState.lastWorkpaperId === WorkpaperTypes.DecisionTree) {
                            // toggleState always false b/c sign-off doesn't apply
                            currentnavtoggleState = false;
                            that.$scope.navCompensation.selected = false;
                            that.$scope.navDecisionTree.selected = true;
                            itemSelected = that.$scope.navDecisionTree;
                            that.currentNavItem = itemSelected;
                        }

                        that.updateAllSignedGui();
                    }
                    else {
                        if (!that.$scope.navCompensation.toggleState && that.currentNavItem.id === 0) {
                            that.updateToggleStates(WorkpaperTypes.Compensation, that.$scope.navCompensation.toggleState);
                        }
                    }

                    that.$rootScope.$broadcast("workpaper-changed", that.currentNavItem.id, currentnavtoggleState);
                }
                else {
                    this.$rootScope.addAlert("danger", errormsg);
                    return null;
                }
            }).catch((response) => {
                this.$rootScope.addAlert("danger", errormsg);
                return null;
            })
                .finally(() => {
                    this.$scope.showBusyLoader = false;
                });
        }

        public broadcastAllWorkpapersSigned = (allSigned: boolean) => {
            this.$rootScope.$broadcast("workpapers-all-signed", allSigned);
        }

        public broadcastWorkpaperSigned = (workpaperType: WorkpaperTypes, currentSigned: boolean) => {
            this.$log.debug("workpaper " + workpaperType + " is signed: " + currentSigned);
            this.$rootScope.$broadcast("workpaper-signed", workpaperType, currentSigned);
        }

        public broadcastLeftNavLoaded = () => {
            this.$scope.$emit("left-nav-loaded");
        }

        private confirmUnsign = (workpaperType: WorkpaperTypes): void => {
            let that = this;
            this.modalInstance = this.$uibModal.open({
                templateUrl: "removeSignModalConfirmation.html",
                controller: function ($scope, $rootScope, $window, $uibModalInstance) {
                    $scope.continue = () => {
                        that.updateToggleStates(workpaperType, false, true);
                        $uibModalInstance.close();
                    };

                    $scope.cancel = () => {
                        $uibModalInstance.dismiss("unsign-close");
                        that.updateToggleStates(workpaperType, true, false);
                    };
                },
                size: "sm",
            });

            that.modalInstance.result.then((result) => {
                //everything is good! continue
            }, function (reason) {  //on dismiss
                that.onToggleChange(workpaperType, true, false);
            });
        };

        /* tslint:disable-next-line:no-unused-variable */
        private $onInit(): void {
            this.$scope.showBusyLoader = true;
            this.$log.debug("Initializing WpList");
            const locatorId: string = this.$ledoconfig.getCookieStorage("locator");
            let defer = this.WpWorkpaperService.getAsync(locatorId);

            this.$scope.navCompensation = {} as INavTreeItem;
            this.$scope.navForeignTaxes = {} as INavTreeItem;
            this.$scope.navStateSourcing = {} as INavTreeItem;
            this.$scope.navOptimized = {} as INavTreeItem;
            this.$scope.navDecisionTree = {} as INavTreeItem;

            let that = this;
            const error = "Sign off status failed to load. Please refresh your screen.";

            defer.then(function (response) {
                if (response.data.status === "success") { // if there is a success handler
                    that.$scope.showOptimized = (response.data.workpapers[4] == null) ? false : response.data.workpapers[4].visible;

                    that.loadTreeList();

                    that.$scope.navCompensation.toggleState = response.data.workpapers[1] != null &&
                    response.data.workpapers[1].signedOff === true ? true : false;
                    that.$scope.navForeignTaxes.toggleState = response.data.workpapers[2] != null &&
                    response.data.workpapers[2].signedOff === true ? true : false;
                    that.$scope.navStateSourcing.toggleState = response.data.workpapers[3] != null &&
                    response.data.workpapers[3].signedOff === true ? true : false;
                    that.$scope.navOptimized.toggleState = response.data.workpapers[4] != null &&
                    response.data.workpapers[4].signedOff === true ? true : false;

                    that.getLoginState(that.$scope.navCompensation.toggleState,
                        that.$scope.navForeignTaxes.toggleState,
                        that.$scope.navStateSourcing.toggleState,
                        that.$scope.navOptimized.toggleState);
                } else {
                    that.$rootScope.addAlert("danger", error);
                }
            }).catch((response) => {
                that.$rootScope.addAlert("danger", error);
            })
                .finally(() => {
                    that.broadcastLeftNavLoaded();
                    this.$scope.showBusyLoader = false;
                });
        }

        private updateToggleStatesGui(toggleStates, value): void {
            switch (toggleStates) {
                case WorkpaperTypes.Compensation:
                    this.$scope.navCompensation.toggleState = value;
                    break;

                case WorkpaperTypes.ForeignTaxes:
                    this.$scope.navForeignTaxes.toggleState = value;
                    break;

                case WorkpaperTypes.StateSourcing:
                    this.$scope.navStateSourcing.toggleState = value;
                    break;

                case WorkpaperTypes.Optimized:
                    this.$scope.navOptimized.toggleState = value;
                    break;
            }

            this.$rootScope.$broadcast("workpaper-signed", toggleStates, value);

            this.updateAllSignedGui();
        }

        private updateAllSignedGui() {
            // broadcast whether or not all workpapers are signed
            // always consider Compensation, ForeignTaxes, and StateSourcing; if optimzation is visible also consider Optimized    
            let allSigned = (this.$scope.navCompensation.toggleState &&
                this.$scope.navForeignTaxes.toggleState &&
                this.$scope.navStateSourcing.toggleState);

            if (this.$scope.showOptimized) {
                allSigned = allSigned && this.$scope.navOptimized.toggleState;
            }

            this.broadcastAllWorkpapersSigned(allSigned);
        }
    }

    class WpList implements ng.IComponentOptions {
        public templateUrl: string;
        public controllerAs: string;
        public controller: any;

        constructor() {
            this.templateUrl = "/app/components/workpapers/wpleftnav/wpleftnav.html";
            this.controllerAs = "model";
            this.controller = ["$log", "$rootScope", "$scope", "$ledoconfig", "$uibModal", "WpWorkpaperService", "WpLoginStateService",
                "config", WpListComponentController];
        }
    }

    angular.module("workpapers").component("wpLeftNav", new WpList());
}