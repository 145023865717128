namespace workpapers {
    "use strict";

    interface ISignOffService {
        getAsync(eventType: string,  taxYear: string, locatorName: string, locatorId: string, row: string, 
          sheetName: string, workpaperId: string, accountId: string, userName: string): ng.IPromise<any>;
        signOffAsync(eventType: string,  taxYear: string, locatorName: string, locatorId: string, row: string, 
          sheetName: string, workpaperId: string, accountId: string, userName: string): ng.IPromise<any>;
    }

    export class WpWorkpaperService {
        private controller = "/api/workpapers";

        constructor(public $log: ng.ILogService, public $http: ng.IHttpService, public config: any) { }

        public getAsync(locatorId: string): ng.IPromise<any> {
            const defer = this.$http.post(this.config.api + this.controller + "/get", {
                locatorId: locatorId,            
            });
            return defer;
        }

        public signOffAsync(locatorId: string, workpaperId: string, signedOff: boolean): ng.IPromise<any> {
            const defer = this.$http.post(this.config.api + this.controller + "/signoff", {
                locatorId: locatorId,            
                workpaperId: workpaperId,
                signedOff: signedOff,
            });
            return defer;
        }        
    }

    angular
        .module("workpapers")
        .service("WpWorkpaperService", WpWorkpaperService);
}
